export default {
    list: ["Plan and carry out a scavenger hunt or treasure hunt for your partner",
        "Go tubing, kayaking, or canoeing together",
        "Go fruit picking together",
        "Enjoy a candlelight dinner together at your home or apartment, Dress up!",
        "Go for a bike ride together",
        "Go bowling together, (Feeling light-hearted? Try “bowling with a twist” --between the legs, on one leg, left-handed, etc,)",
        "Attend a local festival together",
        "Find somewhere you can feed the ducks together (note: it’s better for the ducks if you avoid bread and feed birdseed or grapes instead)",
        "Get back in touch with your inner child and finger paint together",
        "Plan a future date you can go on together with a strict budget limit ($5, $10)",
        "Put together unusual outfits from a thrift store and go out while “in character",
        "Attend a free class together at your local library",
        "Enjoy a board game night, Your area may have a game store where you can come and try out games at a certain time",
        "Attend a play or musical event together",
        "Plan and carry out a small fundraiser together for a cause you both believe in",
        "Prepare an unusual meal together (for instance, sushi or homemade ravioli)",
        "Play cards together, Learn some new games",
        "Visit an amusement park together",
        "Visit a historic or beautiful cemetery together",
        "Enjoy some active two-person games and sports together (tennis, racquetball, Ping-Pong, croquet, Frisbee…)",
        "Build a campfire together and enjoy sitting by it and talking, Roast marshmallow or hot dogs",
        "Play Guitar Hero or other interactive video games together",
        "Watch early morning cartoons and make breakfast together",
        "Visit an art museum together, In each room, pick your favorite piece of art and talk about why you like it",
        "Go for a beautiful hike in a natural area together",
        "Explore a cave together (research this one first!)",
        "Go hunting or fishing together",
        "Visit an odd, unusual or quirky tourist attraction together, Check out Atlas Obscura for some really unique sites!",
        "Visit the zoo together, Which are your favorite animals and why? What animal reminds you of yourself or your date?",
        "Go horseback riding together",
        "Visit an agritourism attraction(pumpkin patch, corn maze, etc,) together",
        "Attend a talk or lecture together and discuss it afterwards",
        "Seedbomb together(This is about flowers!)",
        "Build and fly a kite together",
        "Go ice skating",
        "Go stargazing",
        "Attend a workshop at a local crafts or hardware store",
        "Visit a paint - your - own pottery studio together",
        "Hold a “mock Olympics” with silly events and goofy prizes",
        "Make a ridiculous video on your smartphones using one of the many apps available",
        "Have an ice cream sundae night—buy everything you’ve ever wanted for a sundae and build them high!",
        "Design and build a gingerbread house together",
        "Attend a wine tasting coffee tasting or other free tasting event together",
        "Visit a factory vineyard or brewery that gives tours",
        "Picnic together",
        "Go to a drive -in movie together",
        "Visit a video arcade together and challenge each other to see who can score the highest on different games",
        "Go letterboxing or geocaching together",
        "Play old - fashioned playground games together: jacks 4-square hopscotch",
        "Play a game of paintball together",
        "Visit a high - ropes course or zip line course together",
        "Play charades",
        "Attend an author reading at a bookstore",
        "Visit the library together and pick out a book for each other",
        "Visit a flea market or antique store together",
        "Try birdwatching—use one of the many apps available for beginners, or check out the walks your local Audubon society has to offer",
        "Make popcorn and hot cocoa and work on a puzzle together",
        "Make a craft together (There are million kits out there!)",
        "Have a water balloon or water gun battle",
        "Make pizza together",
        "Visit open houses together and check out houses you’d probably never buy",
        "Take a fancy car on a test drive",
        "Give each other a foot soak, pedicure, and foot massage using fancy lotions",
        "Go miniature golfing together",
        "Take a dance class together",
        "Play laser tag together",
        "Go roller skating",
        "Do karaoke together",
        "Take a cooking class",
        "Visit the farmers’ market together",
        "Go yardsaling together",
        "Take a day trip together and explore the next town over",
        "Build a blanket fort and watch movies in your PJs",
        "Go to a coffee shop together and learn to Zentangle",
        "Play Mad Libs together",
        "Take a DJing class together",
        "Check out your local historic sites together",
        "Visit a botanical garden",
        "Go on a ghost tour",
        "Play pool foosball, or darts",
        "Go - Kart together",
        "Book a horse and carriage ride",
        "Go to a garden center together and pick out some plants or houseplants",
        "Volunteer at an animal shelter together",
        "Surprise your mate! Plan a total surprise date for your partner, Then switch roles next time",
        "Take over an empty playground",
        "Attend a local sports event",
        "Watch each others’ favorite childhood movies, indulging in favorite childhood snacks while you do ",
        "Windowshop together",
        "Go eyebombing (don't worry—this is fun and harmless) ",
        "Do a photo shoot together with ridiculous outfits and crazy poses",
        "Go to a trivia night together",
        "Learn a new skill from YouTube together",
        "Build or paint a simple piece of furniture together",
        "Make cupcakes and decorate them with weird candy",
        "Browse a used bookstore together",
        "Take an exercise class together",
        "Visit a rock climbing gym together",]

}


