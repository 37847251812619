<template>
  <v-app>
    <div v-if="dateList" class="mydiv">
      <div class="text-center pt-12">
        <h1 class="appText">We should be doing...</h1>
      </div>

      <div class="text-center pt-6 ma-12" style="min-height: 250px">
        <v-slide-y-transition>
          <h3 v-if="load" class="appTextBody">{{ loaded }}</h3>
        </v-slide-y-transition>
      </div>

      <div class="appText text-center">
        <v-icon class="image" size="50" color="white" @click="refresh()"
          >mdi-refresh</v-icon
        >
      </div>
    </div>
  </v-app>
</template>

<script>
import list from "./list/listOfDateIdeas";
export default {
  name: "App",

  mounted() {
    this.loading = true;
    this.dateList = list.list;
    this.loaded = this.randomizeDate();
    setTimeout(() => {
      this.load = true;
    }, 1000);
  },

  data: () => ({
    loading: false,
    selection: 3,
    quote: "",
    dateList: null,
    load: false,
    loaded: "",
  }),
  methods: {
    refresh() {
      this.load = false;
      this.loaded = this.randomizeDate();
      setTimeout(() => {
        this.load = true;
      }, 500);
    },
    randomizeDate() {
      this.loading = false;
      return this.dateList[Math.floor(Math.random() * this.dateList.length)];
    },
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

@font-face {
  font-family: "Cereal";
  src: local("Cereal"),
    url(./fonts/airbnb-cereal-font/AirbnbCereal_W_Md.woff) format("woff");
}
@font-face {
  font-family: "Cereal-light";
  src: local("Cereal-light"),
    url(./fonts/airbnb-cereal-font/AirbnbCereal_W_Lt.woff) format("woff");
}

.mydiv {
  background-color: #b71c1c;
  width: 100%;
  min-height: 100vh;
}

.appText {
  color: white;
  font-family: Cereal, Raleway, Roboto;
}

.appTextBody {
  color: white;
  font-family: Cereal-light, Raleway, Roboto;
}

.image {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
